<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Key *"
                  v-model.number="key"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.key"
                  :error="!!errors.key"
                  :error-messages="errors.key"
                />
                <va-input
                  label="Type"
                  v-model="type"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.type"
                  :error="!!errors.type"
                  :error-messages="errors.type"
                />
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {

      key: '',
      type: 'desc',
      content: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_DEV_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    submit () {
      const data = {
        key: this.key,
        type: this.type,
        content: this.content,
        published: this.published,
        company_id: 1,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/quotes/${this.$attrs.id}`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/quotes`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/quotes/${this.$attrs.id}`)
          .then(response => {
            this.key = response.data.key
            this.type = response.data.type
            this.content = response.data.content
            this.published = !!response.data.published
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
